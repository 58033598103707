:root,
body,
#root {
  background-color: #282c34;
  height: 100%;
  margin: 0;
  padding: 0;
}

#listing::-webkit-scrollbar-track {
  border-radius: 10px;
}

#listing::-webkit-scrollbar {
  width: 12px;
}

#listing::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #6f6f6f;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 0 auto;
}

@media (max-width: 720px) {
  .sm-only {
    display: unset;
  }
}

@media (min-width: 720px) {
  .sm-only {
    display: none;
  }
}

@media (max-width: 720px) {
  .App {
    width: 95%;
  }
}

@media (min-width: 720px) {
  .App {
    width: 90%;
  }
}

@media (min-width: 1280px) {
  .App {
    width: 75%;
  }
}

header {
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 640px) {
  header h1 {
    font-size: 12px;
  }
}

@media (min-width: 640px) {
  header h1 {
    font-size: 14px;
  }
}

@media (min-width: 1280px) {
  header h1 {
    font-size: 18px;
  }
}

header h1 {
  margin: 0;
  color: white;
}

footer {
  display: flex;
  align-items: center;
  left: 0;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;

  background-color: #3c3d3b;
  box-shadow: 1px 4px 10px 5px rgb(0 0 0 / 0.5);
  padding: 12px 8px;
  color: white;
}

.timer-bar {
  transition: width 300ms linear;
  height: 20px;
  border-radius: 4px;
  align-self: center;
}

.current {
  line-height: 32px;
  background: greenyellow !important;
  font-weight: 700;
  animation: pulse 2s ease-in-out infinite;
}

@media (max-width: 720px) {
  .toggled-current .next-video {
    display: none;
  }

  .toggled-next .current-video {
    display: none;
  }
}

.next {
  background: orange !important;
  line-height: 24px;
  font-weight: 700;
}

@keyframes pulse {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.9;
  }

  to {
    opacity: 1;
  }
}

.item {
  display: flex;
  box-sizing: border-box;
  border-radius: 6px;
  background: #eee;
  margin-bottom: 3px;
  padding: 0.25em;
  font-size: 14px;
  font-weight: 700;
  width: 100%;
  transition: 300ms;
}

.full-height {
  height: 100%;
}

#grid {
  display: grid;
}

@media (max-width: 720px) {
  #grid {
    grid-template-columns: 1fr;
    grid-template-areas:
      'header'
      'current'
      'list'
      'footer';
  }
}

@media (min-width: 720px) {
  #grid {
    grid-template-columns: 2fr 1fr;
    grid-template-areas:
      'header header'
      'list current'
      'list current'
      'footer footer';
    grid-gap: 8px;
  }
}

#listing {
  grid-area: list;
  overflow-y: scroll;
  padding: 0 8px;
}

#current-video {
  grid-area: current;
  padding: 0 8px;
}

header {
  grid-area: header;
}

footer {
  grid-area: footer;
}

.card {
  border-radius: 6px;
  padding: 0 4px;
  padding-bottom: 4px;
  margin-bottom: 10px;
}

.card h2 {
  font-size: 12px;
  padding: 2px 0;
  font-weight: normal;
  margin: 0 0 4px 0;
  text-align: center;
}

.h-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rest-placeholder {
  width: 100%;
  height: 128px;
  background: green;
  color: white;
  font-weight: 700;
  border-radius: 4px;
}

video {
  width: 100%;
  border-radius: 4px;
  display: block;
}

.current-video {
  background: greenyellow;
}

.next-video {
  width: 75%;
  background: orange;
}

.repeat-counter {
  flex-basis: 80px;
  padding-left: 8px;
  font-size: 11px;
}

.splash {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

button.btn {
  padding: 16px 32px;
  background-color: greenyellow;
  color: green;
  font-weight: 700;
  border: 0;
  border-radius: 4px;
  box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.65);
}

button.tight {
  padding: 6px 8px !important;
}

a {
  color: #67d5ff;
}

.ex-image {
  max-width: 100%;
}

button.link {
  font-size: 100%;
  font-family: inherit;
  font-weight: inherit;
  border: 0;
  padding: 0;
  background: none;
  cursor: pointer;
  text-align: left;
}

.message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: white;
  font-weight: 700;
}

/* utils */
.flip-h {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
